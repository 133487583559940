import React from "react";
import { NavLink } from "react-router-dom";

const Logo = () => {
    return (
        <>
            <NavLink className="c-sidebar-brand-full" to="/" activeStyle={{ textDecoration: 'none', backgroundColor: 'white'}}>
                <h4 className="text-light c-sidebar-brand-full">
                    <p className="h4 mb-0 ml-4 font-weight-normal" style={{fontFamily: 'sans-serif',textAlign: 'left', color: '#0a2c66'}}><strong>Digital</strong>Banc&nbsp;</p>
                </h4>
            </NavLink>
        </>
    )
}

export default Logo;