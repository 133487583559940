import React from "react";
import Button from '@material-ui/core/Button';

import { ReactComponent as LaunchIllustration } from "../../../assets/undraw_launch.svg";
import { ReactComponent as SavingIllustration } from "../../../assets/undraw_savings.svg";
import { ReactComponent as SecurityIllustration } from "../../../assets/undraw_security.svg";
import { ReactComponent as SecureIllustration } from "../../../assets/undraw_secure.svg";
import { ReactComponent as WalletIllustration } from "../../../assets/undraw_wallet.svg";
import { ReactComponent as WebappIllustration } from "../../../assets/digitalbanc_view.svg";

const Home = () => {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="row justify-content-md-center" style={{backgroundColor: '#fafafa', padding: 0}}>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                            <div className="row mt-4 justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'right', verticalAlign: 'middle', padding: '15px'}}>
                                        <span>
                                            <p className="h1 mb-0 font-weight-bold" style={{fontFamily: 'inherit'}}>SIMPLE</p>
                                            <p className="h1 mb-4 font-weight-bold" style={{fontFamily: 'inherit'}}>BANKING EXPERIENCE</p>
                                            <p className="h5 mb-4 font-weight-normal" style={{fontFamily: 'inherit'}}>No&nbsp;setup&nbsp;fee. No&nbsp;minimum&nbsp;balance. No&nbsp;monthly fee.</p>
                                            <Button className="mb-4" variant="contained" color="default" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px', paddingBottom: '10px', fontSize: '14px', backgroundColor: '#0a2c66', color: 'white'}} onClick={(e) => { 
                                                e.preventDefault();
                                                openInNewTab("https://app.digitalbanc.com/sign-up");
                                            }}>Get Started</Button>
                                            <p className="h6 mb-2 font-weight-normal" style={{fontFamily: 'inherit', cursor: 'pointer', color: '#0a2c66'}} onClick={(e) => { 
                                                e.preventDefault();
                                                openInNewTab("https://app.digitalbanc.com/login");
                                            }}><small>Already&nbsp;have&nbsp;an&nbsp;account? ›</small></p>
                                            <p className="h6 mb-2 font-weight-normal" style={{fontFamily: 'inherit', cursor: 'pointer', color: '#0a2c66'}} onClick={(e) => { 
                                                e.preventDefault();
                                                openInNewTab("https://app.digitalbanc.com/legal/privacy");
                                            }}><small>Learn how we collect and use your information by visiting our Privacy Policy ›</small></p>
                                            <p className="h6 mb-0 font-weight-normal" style={{fontFamily: 'inherit'}}><small><i>DigitalBanc is a financial technology company, not a bank. Card is issued by Pathward, N.A, Members FDIC, pursuant to license by Mastercard International Incorporated. Card may be used everywhere Debit Mastercard® is accepted.</i></small></p>
                                        </span>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-none d-sm-none d-md-block d-lg-none d-xl-none">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <WalletIllustration width={300} height={300} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-none d-lg-block">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <WalletIllustration width={400} height={400} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-md-center">
                        <div className="col col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
                            <div className='mt-5 mb-0 font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '5px'}}>
                                <span>
                                    <p className="h3 mb-3 font-weight-bold" style={{fontFamily: 'inherit'}}>Smart way to manage your money</p>
                                    <p className="h5 mb-0 font-weight-normal" style={{fontFamily: 'inherit'}}>Access your money anywhere anytime using our native web application, which is also fully responsive for mobile browsers.</p>
                                </span>
                            </div>
                        </div>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                <div style={{ textAlign: 'center', padding: '0px'}}>
                                    <WebappIllustration width="80%" height="80%" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row justify-content-md-center" style={{backgroundColor: '#fafafa'}}>
                        <div className="col col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
                            <div className="row justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-none d-md-block">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <SavingIllustration width={250} height={250} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                    <br/>
                                    <br/>
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '15px'}}>
                                        <span>
                                            <p className="h4 mb-4 font-weight-bold" style={{fontFamily: 'inherit'}}>Demand Deposit Accounts (DDA)</p>
                                            <p className="h6 mb-4 font-weight-normal" style={{fontFamily: 'inherit', textAlign: 'justify'}}>Our cards are enabled with Demand Deposit Accounts (DDAs). This means your card will have a dedicated routing number and account number, to receive funding. Cardholder's can deposit their paycheck, just as you would in a traditional bank account.</p>
                                        </span>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-sm-block d-md-none d-lg-none d-xl-none">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <SavingIllustration width={250} height={250} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-md-center">
                        <div className="col col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
                            <div className="row justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                    <br/>
                                    <br/>
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '15px'}}>
                                        <span>
                                            <p className="h4 mb-4 font-weight-bold" style={{fontFamily: 'inherit'}}>Federal Deposit Insurance Corporation (FDIC) Insurance</p>
                                            <p className="h6 mb-4 font-weight-normal" style={{fontFamily: 'inherit', textAlign: 'justify'}}>Card accounts are insured up to $250,000 by FDIC-insured through the card’s issuing bank. This protects the money held on the cards, in the event of a failure by the sponsor bank. Meanwhile, users can leverage card security features offered by our sponsor bank.</p>
                                        </span>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-none d-md-block">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <SecurityIllustration width={250} height={250} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-sm-block d-md-none d-lg-none d-xl-none">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <SecurityIllustration width={250} height={250} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-md-center" style={{backgroundColor: '#fafafa'}}>
                        <div className="col col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
                            <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'center', verticalAlign: 'middle', padding: '15px'}}>
                                <span>
                                    <p className="h3 mt-4 mb-3 font-weight-bold" style={{fontFamily: 'inherit'}}>Security & support you can trust</p>
                                </span>
                            </div>
                        </div>
                        <div className="col col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
                            <div className="row justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <SecureIllustration width={250} height={250} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '15px'}}>
                                        <span>
                                            <p className="h4 mb-2 font-weight-bold" style={{fontFamily: 'inherit'}}>Serious security</p>
                                            <p className="h6 mb-4 font-weight-normal" style={{fontFamily: 'inherit', textAlign: 'justify'}}>DigitalBanc uses secure processes to protect your information and help prevent unauthorized use.</p>
                                        </span>
                                        <span>
                                            <p className="h4 mb-2 font-weight-bold" style={{fontFamily: 'inherit'}}>Privacy and protection</p>
                                            <p className="h6 mb-4 font-weight-normal" style={{fontFamily: 'inherit', textAlign: 'justify'}}>Your card is issued by Pathward, N.A, Member FDIC, pursuant to license by Mastercard International Incorporated. FDIC insured up to $250,000.</p>
                                        </span>
                                        <span>
                                            <p className="h4 mb-2 font-weight-bold" style={{fontFamily: 'inherit'}}>Anytime support</p>
                                            <p className="h6 mb-4 font-weight-normal" style={{fontFamily: 'inherit', textAlign: 'justify'}}>If you need help, reach out to DigitalBanc Card Assistance team. You may contact our friendly team by email, or check out the Help Center.</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row justify-content-md-center" style={{backgroundColor: '#f0f5fa'}}>
                        <div className="col col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
                            <div className="row justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                    <br/>
                                    <br/>
                                    <div className='mt-0 mb-0 font-weight-light' style={{textAlign: 'left', verticalAlign: 'middle', padding: '15px'}}>
                                        <span>
                                            <p className="h3 mt-4 mb-2 font-weight-bold" style={{fontFamily: 'inherit'}}>Get started</p>
                                            <p className="h6 mb-4 font-weight-normal" style={{fontFamily: 'inherit', textAlign: 'justify'}}>Applying for an account is free and takes less than 2 minutes. It won’t affect your credit score!</p>
                                            <Button className="mb-4" variant="contained" color="default" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px', paddingBottom: '10px', fontSize: '14px', backgroundColor: '#0a2c66', color: 'white'}} onClick={(e) => { 
                                                e.preventDefault();
                                                openInNewTab("https://app.digitalbanc.com/sign-up");
                                            }}>Get Started</Button>
                                        </span>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-none d-md-block">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'left'}}>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <LaunchIllustration width={250} height={250} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;