import React, { useState } from "react";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: 12,
    borderWidth: 1,
    boxShadow: 24,
    p: 4,
  };

const ActionTab = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <li className="c-header-nav-item" id="user-login">
                <Button variant="outlined" color="default" style={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px', paddingBottom: '10px', fontSize: '14px', color: '#0a2c66'}} onClick={handleOpen}>Log In</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Card id="modal-card-banking" className="mb-2" variant="outlined" onClick={(e) => { 
                            e.preventDefault();
                            openInNewTab("https://app.digitalbanc.com/login");
                        }}>
                            <CardActionArea>
                                <div className="row justify-content-md-center">
                                    <div className="col col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                        <CardContent style={{ padding: 16, margin: 0}}>
                                            <Typography className="mt-0 mb-0 font-weight-normal" sx={{ fontSize: 14}} color="primary">
                                                Online Banking
                                            </Typography>
                                        </CardContent>
                                    </div>
                                    <div className="col col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <CardActions style={{ padding: 16, margin: 0}}>
                                            <Typography className="mt-0 mb-0 font-weight-bold" sx={{ fontSize: 14 }} color="primary">
                                            &#8594;
                                            </Typography>
                                        </CardActions>
                                    </div>
                                </div>
                            </CardActionArea>
                        </Card>
                        <Card id="modal-card-investor" className="mb-0" variant="outlined" onClick={(e) => { 
                            e.preventDefault();
                            // openInNewTab("https://dev.davygroup.com/signin");
                        }}>
                            <CardActionArea>
                                <div className="row justify-content-md-center">
                                    <div className="col col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                        <CardContent style={{ padding: 16, margin: 0}}>
                                            <Typography className="mt-0 mb-0 font-weight-normal" sx={{ fontSize: 14 }} color="primary">
                                                <p className="mt-0 mb-0 font-weight-normal">Investor</p>
                                                <p className="mt-0 mb-0 font-weight-normal"><small className="text-muted">Coming Soon</small></p>
                                            </Typography>
                                        </CardContent>
                                    </div>
                                    <div className="col col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <CardActions style={{ padding: 16, margin: 0}}>
                                            <Typography className="mt-0 mb-0 font-weight-bold" sx={{ fontSize: 14 }} color="primary">
                                            &#8594;
                                            </Typography>
                                        </CardActions>
                                    </div>
                                </div>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Modal>
            </li>
        </>
    )
}

export default ActionTab;