import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NotFoundIllustration } from "../../../assets/undraw_404.svg";

const PageNotFound = () => {
    const title = 'PAGE NOT FOUND';
    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="wrapper">
                        <div class="header container">
                            <div className="row justify-content-md-center">
                                <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className='mb-0 font-weight-light' style={{textAlign: 'center'}}>
                                        <div className="mb-2" style={{ textAlign: 'center', padding: '0px'}}>
                                            <NotFoundIllustration width="350px" height="350px" />
                                        </div>
                                        <div style={{ textAlign: 'center', padding: '0px'}}>
                                            <p className="h3 mb-4 font-weight-normal" style={{color: 'black', fontFamily: 'inherit'}}>{title}</p>
                                        </div>
                                        <Link className="btn btn-secondary mt-2" to="/">
                                            Go Back
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;