import React from "react";
import UserLogin from '../../NavBar/ActionTab';
import Logo from '../../Logo';
import Divider from '@material-ui/core/Divider';

const PublicTemplate = ({ children }) => {
    return (
		<div className="c-app">
            <div className="c-wrapper">
                <header style={{height: 'fit-content', backgroundColor: 'white', position: 'sticky', top: 0, opacity: 0.95, zIndex: 10}}>
                    <div className="row justify-content-md-center">
                        <div className="col col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
                            <div className="c-header c-header-light c-header-fixed" style={{height: 'fit-content', backgroundColor: 'transparent', borderColor: 'white'}}>
                                <div className="c-sidebar-brand mfe-auto" >
                                    <Logo />
                                </div>
                                <ul className="c-header-nav mr-4">
                                    <UserLogin />
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
				<div className="c-app flex-row align-items-center" style={{backgroundColor: 'white', padding: 0}}>
				 	<div className="container" style={{maxWidth: 'fit-content'}}>
				 		<div className="justify-content-center row">
				 			{children}
				 		</div>
				 	</div>
				</div>
                <footer>
                    <div className="c-footer c-footer-light c-footer-fixed" style={{height: 'fit-content', backgroundColor: '#0a2c66', textAlign: 'center'}}>
                        <div className="row justify-content-md-center" style={{width: '100%', backgroundColor: '#0a2c66', textAlign: 'center'}}>
                            <div className="col col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10" style={{textAlign: 'justify'}}>
								<br/>
								<Divider />
								<br/>
								<p className="h6 mb-3 font-weight-light" style={{textAlign: 'center'}}><small className="mr-3 ml-3 text-muted">DigitalBanc is a financial technology company, not a bank. Cash back and other merchant rewards are not offered or endorsed by Pathward, N.A. View our <a target="_blank" rel="noreferrer" href="https://app.digitalbanc.com/legal/terms">Terms of Service ›</a></small></p>
                                <p className="h6 mb-3 font-weight-light" style={{textAlign: 'center'}}><small className="mr-3 ml-3 text-muted">The Select Prepaid Mastercard® is issued by Pathward, N.A, Member FDIC, pursuant to license by Mastercard International Incorporated. Mastercard® is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated. The Select Prepaid Mastercard® may be used everywhere Debit Mastercard is accepted.</small></p>
                                <p className="h6 mb-3 font-weight-light" style={{textAlign: 'center'}}><small className="mr-3 ml-3 text-muted">By clicking on some of the links above, you will leave the DigitalBanc website and be directed to a third-party website. The privacy practices of those third parties may differ from those of DigitalBanc. We recommend you review the privacy statements of those third party websites, as DigitalBanc is not responsible for those third parties' privacy or security practices.</small></p>
								<p className="h6 mb-3 font-weight-light" style={{textAlign: 'center'}}><small className="mr-3 ml-3 text-muted">Learn how we collect and use your information by visiting our <a target="_blank" rel="noreferrer" href="https://app.digitalbanc.com/legal/privacy">Privacy Policy ›</a></small></p>
								<br/>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default PublicTemplate;