import React from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PublicRouter from './components/routers/PublicRouter';
import PublicTemplate from './components/templates/PublicTemplate';

// APP CSS
import "./App.css";

// PUBLIC
import Home from './pages/public/Home';
import PageNotFound from './pages/public/PageNotFound';

function App() {
	return (
		<Router>
			<Switch>
				<PublicRouter exact path="/" component={Home} template={PublicTemplate} title="DigitalBanc" />
				<PublicRouter component={PageNotFound} template={PublicTemplate} title="Page Not Found" />
          </Switch>
        </Router>
  )
}

export default App;
